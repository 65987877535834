import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import * as styles from "../styles/404.module.css"

export default function NotFound() {
  return (
    <Layout>
      <Helmet>
        <meta name="description" content="404 Page" />
        <title>Patrick Oldman | 404 Error </title>
      </Helmet>
      <div>
        <h1>Oops!!!! 404 - this page does not exist :(</h1>
        <div className={styles.btn}>
          <Link to="/">Return to Safety</Link>
        </div>
      </div>
    </Layout>
  )
}
